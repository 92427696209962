import { type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { useEffect, useRef } from "react";
import { generatePath } from "react-router-dom";

import { Button } from "../../components/Button";
import { VirtualShiftListContextProvider } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import {
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
  SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH,
} from "../../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkplaceModalContent } from "../Modal/Content";
import { WorkplaceModalFloatingFooter } from "../Modal/FloatingFooter";
import { WorkplaceModalHeader } from "../Modal/Header";
import type { WorkplaceProfile } from "../types";
import { WorkplaceOpenShiftsList } from "./List";
import { useWorkplaceOpenShiftsData } from "./useWorkplaceOpenShiftsData";

interface WorkplaceOpenShiftsModalProps {
  modalState: UseModalState;
  workplaceId: string;
  workplace?: WorkplaceProfile;
  isLoading: boolean;
}

export function WorkplaceOpenShiftsModal(props: WorkplaceOpenShiftsModalProps) {
  const { modalState, workplaceId, workplace, isLoading: isWorkplaceLoading } = props;
  const { name } = workplace ?? {};

  const {
    // we don't want to filter shifts in the workplace open shifts modal, we want users to see all shifts
    data: { unfilteredWorkplaceShifts: workplaceShifts, workplacesMap },
    isError: openShiftsIsError,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    pagination,
  } = useWorkplaceOpenShiftsData(workplace);

  const { setBaseNavigationPathOverride } = useShiftModalsDataContext();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  /**
   * When this modal is opened, we want to override the base navigation path
   * so that when shift modals are opened from here, they are rendered on top of this workplace modal
   */
  useEffect(() => {
    const path = generatePath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
      workplaceId,
    });

    setBaseNavigationPathOverride(path);

    return () => {
      setBaseNavigationPathOverride(undefined);
    };
  }, [setBaseNavigationPathOverride, workplaceId]);

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <VirtualShiftListContextProvider shifts={workplaceShifts} workplacesMap={workplacesMap}>
        <WorkplaceModalHeader title={name} isLoading={isWorkplaceLoading} />

        <WorkplaceModalContent ref={scrollRef} sx={{ display: "flex", flexDirection: "column" }}>
          <Stack spacing={8} sx={{ flex: 1 }}>
            <WorkplaceOpenShiftsList
              scrollRef={scrollRef}
              isEmpty={workplaceShifts.length === 0}
              isError={openShiftsIsError}
              isLoading={openShiftsIsLoading || isWorkplaceLoading}
              isSuccess={openShiftsIsSuccess}
              workplace={workplacesMap.get(workplaceId)!}
              loadMore={() => {
                void pagination?.fetchNextPage();
              }}
              canLoadMore={Boolean(pagination?.hasNextPage)}
              isLoadingMore={pagination?.isFetchingNextPage ?? false}
            />
          </Stack>
        </WorkplaceModalContent>

        <WorkplaceModalFloatingFooter>
          <Button
            fullWidth
            variant="contained"
            sx={(theme) => ({
              // !important is needed to override the default `box-shadow: none` on the button
              boxShadow: `${theme.shadows[3]} !important`,
            })}
            href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH, {
              workplaceId,
            })}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.VIEWED_FACILITY_PROFILE, {
                workplaceId,
                trigger: "Workplace Open Shifts Modal",
              });
            }}
          >
            View profile
          </Button>
        </WorkplaceModalFloatingFooter>
      </VirtualShiftListContextProvider>
    </FullScreenDialog>
  );
}
