import { Stack, type SxProps, type Theme } from "@mui/material";

import type { IconType } from "../../components/CbhIcon";
import { Pill } from "../../components/Pill";
import { PillSkeleton } from "../../components/PillSkeleton";
import { WorkplaceHighlightType } from "./constants";
import { type WorkplaceHighlight } from "./types";

export interface WorkplaceHighlightPillsProps {
  highlights: WorkplaceHighlight[];
  isLoading?: boolean;
  wrapperSx?: SxProps<Theme>;
}

const iconTypeByHighlightType: Record<WorkplaceHighlightType, IconType> = {
  [WorkplaceHighlightType.AM_PATIENTS_PER_WORKER]: "sun-outlined",
  [WorkplaceHighlightType.PM_PATIENTS_PER_WORKER]: "sunset-outlined",
  [WorkplaceHighlightType.NOC_PATIENTS_PER_WORKER]: "moon-outlined",
  [WorkplaceHighlightType.CANCELLATION_PAYOUT]: "extra-pay",
  [WorkplaceHighlightType.CHARTING_SOFTWARE]: "software",
  [WorkplaceHighlightType.DRESS_CODE]: "dress-code",
  [WorkplaceHighlightType.EXTRA_TIME_PAY]: "extra-pay",
  [WorkplaceHighlightType.BREAKS]: "break",
  [WorkplaceHighlightType.WELL_SUPPLIED]: "supplies",
};

export function WorkplaceHighlightPills(props: WorkplaceHighlightPillsProps) {
  const { highlights, isLoading, wrapperSx } = props;

  if (isLoading) {
    return (
      <Stack direction="row" flexWrap="wrap" gap={3}>
        <PillSkeleton />
        <PillSkeleton />
        <PillSkeleton />
        <PillSkeleton />
      </Stack>
    );
  }

  return (
    <Stack direction="row" flexWrap="wrap" gap={3} sx={wrapperSx}>
      {highlights
        // Doesn't make sense to display highlights without a label.
        // Should never happen, but it's here just to be safe.
        .filter((highlight) => highlight.label)
        .map((highlight) => (
          <Pill
            key={highlight.type}
            size="medium"
            label={highlight.label}
            iconType={iconTypeByHighlightType[highlight.type]}
            sx={{ width: "fit-content" }}
          />
        ))}
    </Stack>
  );
}
