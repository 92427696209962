import { IconButton } from "../../components/IconButton";
import { PageHeaderTopRow } from "../../components/PageHeaderTopRow";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";

interface WorkplaceModalHeaderProps {
  title?: string;
  isLoading?: boolean;
}

export function WorkplaceModalHeader(props: WorkplaceModalHeaderProps) {
  const { title, isLoading } = props;

  return (
    <PageHeaderWrapper component="div" sx={(theme) => ({ zIndex: theme.zIndex.modal })}>
      <PageHeaderTopRow
        title={title}
        isLoading={isLoading}
        rightAction={
          <IconButton
            invert
            iconType="like-outlined"
            variant="contained"
            size="small"
            sx={(theme) => ({
              boxShadow: theme.shadows[2],
              // This is here to make it easier to center
              // the workplace name in the header, but it's
              // hidden until we implement the like functionality.
              visibility: "hidden",
              pointerEvents: "none",
            })}
            onClick={() => {
              // TODO: Implement like button
            }}
          />
        }
      />
    </PageHeaderWrapper>
  );
}
