import { isDefined } from "@clipboard-health/util-ts";
import { convertToGeoLocation } from "@src/appV2/Location/utils";
import { ShiftDiscoverySearchMode } from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import { parseISO } from "date-fns";

import { useGetOpenShiftsForDates } from "../../Shift/Open/useGetOpenShiftsForDates";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../../ShiftDiscovery/Filters/constants";
import { filterOpenShifts } from "../../ShiftDiscovery/Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";
import { useShiftDiscoverySearchMode } from "../../ShiftDiscovery/useShiftDiscoverySearchMode";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import type { WorkplaceProfile } from "../types";

export function useWorkplaceOpenShiftsData(workplace?: WorkplaceProfile) {
  const coordinates = convertToGeoLocation(workplace?.geoLocation?.coordinates ?? [0, 0]);
  const {
    dates: dateStrings,
    shiftBookingTypes,
    // Filtering by timeslot happens at the list rendering level
    // because each date can have a different set of timeslots
    shiftTimeSlots: timeSlotFilters,
    licenses,
  } = useShiftDiscoveryUserFiltersContext();

  const searchMode = useShiftDiscoverySearchMode();

  const dates =
    searchMode === ShiftDiscoverySearchMode.LIST
      ? []
      : dateStrings.map((dateString) => parseISO(dateString));

  const openShiftsResult = useGetOpenShiftsForDates({
    dates,
    dateRange: getShiftDiscoveryDefaultDateRange(180),
    filter: {
      "workplace.id": workplace?.userId,
      area: {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        radiusInMiles: DEFAULT_DISTANCE_IN_MILES_FILTER,
      },
    },
    enabled: isDefined(workplace) && coordinates.latitude !== 0 && coordinates.longitude !== 0,
  });

  const unfilteredWorkplaceShifts = openShiftsResult.shifts;

  const filteredWorkplaceShifts = filterOpenShifts(
    { shifts: unfilteredWorkplaceShifts },
    { dates: dateStrings, licenses, shiftBookingTypes }
  );

  return {
    ...openShiftsResult,
    data: {
      filteredWorkplaceShifts,
      unfilteredWorkplaceShifts,
      workplacesMap: openShiftsResult.workplacesMap,
      timeSlotFilters,
    },
  };
}
