import { isDefined } from "@clipboard-health/util-ts";
import { useGetExtraTimePaySettings } from "@src/appV2/Facilities/api/useGetExtraTimePaySettings";
import { type Facility } from "@src/appV2/Facilities/types";
import type { AxiosError } from "axios";

import { useWorkplaceNotesParsed } from "../useWorkplaceNotesParsed";
import { buildWorkplacePolicyHighlights } from "./buildWorkplacePolicyHighlights";
import type { WorkplaceHighlight } from "./types";

interface UseWorkplacePolicyHighlightsProps {
  workplaceId: string | undefined;
  lateCancellation: Facility["lateCancellation"];
  requiresLunchBreak: Facility["requiresLunchBreak"];
  providesRestBreaks: Facility["providesRestBreaks"];
}

interface UseWorkplacePolicyHighlightsResult {
  highlights: WorkplaceHighlight[];
  isLoading: boolean;
}

export const useWorkplacePolicyHighlights = (
  props: UseWorkplacePolicyHighlightsProps
): UseWorkplacePolicyHighlightsResult => {
  const { workplaceId, lateCancellation, requiresLunchBreak, providesRestBreaks } = props;

  const { data: workplaceNotes = {}, isInitialLoading: isWorkplaceNotesLoading } =
    useWorkplaceNotesParsed(workplaceId ?? "", {
      enabled: isDefined(workplaceId),
      useErrorBoundary: false,
    });

  const { data: extraTimePaySettings, isInitialLoading: isExtraTimePayLoading } =
    useGetExtraTimePaySettings(workplaceId, {
      retry: (failureCount, error) => {
        // No need to retry if not found
        if ((error as AxiosError).response?.status === 404) {
          return false;
        }

        return failureCount < 3;
      },
      retryOnMount: false,
      refetchOnWindowFocus: (query) => query.state.status !== "error",
    });

  const isExtraTimePayEnabled =
    isDefined(extraTimePaySettings?.workdayOptions) ||
    isDefined(extraTimePaySettings?.weeklyOption);

  return {
    isLoading: isWorkplaceNotesLoading || isExtraTimePayLoading,
    highlights: buildWorkplacePolicyHighlights({
      isExtraTimePayEnabled,
      lateCancellation,
      requiresLunchBreak,
      providesRestBreaks,
      workplaceNotes,
    }),
  };
};
