import { isDefined } from "@clipboard-health/util-ts";
import { minutesToMilliseconds } from "date-fns";

import { useGetMultipleChoiceAggregates } from "../../../Reviews/api/useGetMultipleChoiceAggregates";
import {
  type AggregatedReviews,
  buildWorkplaceReviewHighlights,
} from "./buildWorkplaceReviewHighlights";
import type { WorkplaceHighlight } from "./types";

interface UseWorkplaceReviewHighlightsProps {
  workplaceId?: string;
}

interface UseWorkplaceReviewHighlightsResult {
  highlights: WorkplaceHighlight[];
  isLoading: boolean;
}

export function useWorkplaceReviewHighlights(
  props: UseWorkplaceReviewHighlightsProps
): UseWorkplaceReviewHighlightsResult {
  const { workplaceId } = props;
  const { data: multipleChoiceAggregates = [], isInitialLoading } = useGetMultipleChoiceAggregates(
    workplaceId ?? "",
    {
      enabled: isDefined(workplaceId),
      staleTime: minutesToMilliseconds(5),
      useErrorBoundary: false,
    }
  );

  const aggregatedReviews: AggregatedReviews[] = multipleChoiceAggregates.map((aggregate) => ({
    tags: aggregate.attributes.question.tags.map(({ tag }) => tag),
    text: aggregate.attributes.text,
  }));

  return {
    isLoading: isInitialLoading,
    highlights: buildWorkplaceReviewHighlights({ aggregatedReviews }),
  };
}
